<template>

  <div>
    
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-card no-body>
    
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0  mobile_tab_max_width_flex">
            <!-- <label>Status</label> -->
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sites"
              label="site_name"
              class="w-100"
              placeholder="Project Site"
              @input="filterTable"
              v-model="siteData"
              :clearable="false"
            >
            <template #selected-option="{ site_name }">
                <span v-if="site_name.length < 23">{{site_name}}</span>
                <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
              </template>
            </v-select>
          </b-col>

          

          <!-- <b-col 
           cols="12"
           md="9"
           class="mb-md-0 mb-2"
          >

            <div class="d-flex align-items-center justify-content-end">
            </div>
          </b-col> -->
          
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="mobile_tab_max_width_flex all_btn_tab"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem "
                placeholder="Search..."
                @input="filterTable"
              />
              <b-button
                variant="warning"
                :to="{name:'add-unit'}"
                class=""
              >
                <span class="text-nowrap">Add Unit</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative sTable common_big_tbl"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <!-- Column: User -->
        <template #cell(site)="items">
            <span v-if="items.item.site" class="wordBreak">
              <div class="my-1">{{ items.item.site.site_name | capitalize }}</div>
            </span>         
        </template>

        <!-- Column: Role -->
        <template #cell(name)="items">
           
            <span class="wordBreak">{{ items.item.name | capitalize}}</span>
         
        </template>

        

        
        
        <template #cell(status)="items">
           
            <b-badge
              pill
              :variant="variantColor(items.item.status)"
              class="text-capitalize"
            >
              {{ items.item.status}}
            </b-badge>
         
        </template>

        <template #cell(created_at)="items">
           
            <span>{{sitedateTime(items.item.created_at,siteData.pdf_date_format,siteData.pdf_time_format)}}</span>
         
        </template>

        <template #cell(actions)="items">

          
          <!-- <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            

            <b-dropdown-item :to="{ name: 'edit-unit', params: { id: items.item._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="statusChange(items.item)">
              <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
              <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
              <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteItem(items.item._id)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            

            
          </b-dropdown>  -->

          <b-link  :to="{ name: 'edit-unit', params: { id: items.item._id } }"
            v-b-tooltip.hover.v-warning
            title="Edit"
            variant="outline-warning"
          >
              <feather-icon icon="EditIcon" class="mediumSize mr-1"/>
              
          </b-link>

            <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" @click="statusChange(items.item)">
                <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
            </b-link>

           <b-link @click="deleteItem(items.item._id)"
            v-b-tooltip.hover.v-warning
            title="Delete"
            variant="outline-warning"
          >
              <feather-icon icon="Trash2Icon" class="mediumSize mr-1"/>
              
          </b-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,VBTooltip,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BCardBody
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BBreadcrumb,
    BCardBody
  },
   directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
       tableColumns: [
            { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '25%'}},
            { key: 'name', label: 'Inspection Unit Name', sortable: true , thStyle:  {width: '30%'}},
            //{ key: 'checklist_form', label: 'Checklist Form', sortable: false , thStyle:  {width: '20%'}},
            { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '15%'}},
            { key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '15%'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%'}},
          ],
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        sites : [],
        siteData : ''
    }
  },
  methods : {
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     site:this.siteData ? this.siteData._id : null,
                   },
                   api: '/api/site-unit-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    statusChange(item){
      if (item.status == 'active') {
          var msg = 'Are you sure want to inactivate this record?';
          var message='Unit Inactivated Successfully.';
      } 
      else{
          var msg = 'Are you sure want to activate this record?';
          var message='Unit Activated Successfully.';
      }

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-unit-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },

    deleteItem(id){
      var msg = 'Are you sure want to delete this record?';
      var message='Unit Deleted Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                          status:'deleted'
                        },
                    api:"/api/change-unit-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
              
          })
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'QI & Checklist'
      },{
        to:{name:'forms'},
        text: 'Forms',
      },{
        to:null,
        text: 'Units',
        active:true
      }];
      return item;
    },
    allSites(){
      return this.$store.dispatch(POST_API, {
         data:{
              role:this.$store.getters.currentUser.role,
              //om_sites:this.$store.getters.currentUser.om_sites,
         },
         api: '/api/all-sites'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.sites = this.$store.getters.getResults.data;
              /*this.siteData = this.sites.length > 0 ? this.sites[0] : null;
              this.filterTable();*/

              // if (this.sites.length == 1 ) {
              //     this.siteData = this.sites[0];

              //     this.filterTable();
              // }

              var obj = {
                _id:'all-site',
                site_name:'All Project Sites',
                pdf_date_format: 'DD MMM YYYY',
                pdf_time_format: 'HH:mm'
              }
              this.sites.unshift(obj);

              this.siteData = this.defaultSite(this.sites);
              this.filterTable();
              
              return this.sites;
          }
      });
    },
  },
  mounted(){
    this.allSites();
    
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
